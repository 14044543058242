
.NodeModalHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3%;
    min-height: 3rem;
}

.NodeModalWrapper{
    width: 75vw;
    height: 75vh;
    min-width: 50rem;
    max-width: 50vw;
    max-height: 75vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    position: absolute;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    gap: 0.5rem;
    overflow: hidden;
}

.NodeModalContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49%;
    height: 90%;
    overflow: auto;
    elevation: below;
}

.NodeModalContentContainerHalfSize{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    elevation: below;
}