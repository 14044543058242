
.contentNoQR {
    position: relative;
    width: 400px;
    height: 400px;
    border-radius: 5%
}
.contentNoQR video {
    width: 100%;
    display: block;
}

.kapionVideo{
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.56);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.56);
    border-radius: 5%;
    height:400px
}

.contentNoQR:before {
    content: '';
    position: absolute;
    background: rgba(252, 0, 0, 0.5);

    opacity: 50%;
    border-color: red;
    height: 200px;
    width: 200px;
    border-radius: 5%;
    top: 100px;
    left: 100px;
    z-index: 1;

}

.contentQR {
    position: relative;
    width: 400px;
    heigt: 400px
}
.contentQR video {
    width: 100%;
    display: block;
}
.contentQR:before {
    content: '';
    position: absolute;
    background: rgba(114, 255, 0, 0.5);
    opacity: 50%;
    border-color: rgba(114, 255, 0, 0.5);
    height: 200px;
    width: 200px;
    border-radius: 0%;
    top: 100px;
    left: 100px;
    z-index: 1;

}
