.PersonDot {
    background-color: #00A693;
    border-radius: 50%;
    margin: 10px;
    height: 0.75rem;
    width: 0.75rem;
    border: #00A693;

    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 250, 163, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(0, 250, 163, 0.5);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 250, 163, 0.5);
    }
}