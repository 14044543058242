@keyframes fadeToNormal {
    0% {
        background-color: rgb(0, 165, 146);
    }
    100% {
        background-color: inherit; /* Or specify the normal color */
    }
}

.newMessageHighlight {
    animation: fadeToNormal 2s ease-out; /* Duration: 2s */
}