

.TypographySkeletonAnimation {
  animation:  skeleton-loading  1.5s infinite;
    color: #706e6e;
}

@keyframes skeleton-loading {
    to {
        color: #3f3f3f;
    }
}