html, body, #root {
  height: 100%;
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
/*  Styled Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  transition: 1s;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(43, 43, 43, 0.6);
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: 1s;
}

::-webkit-scrollbar-thumb:vertical:hover,
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: rgb(63, 63, 63);
  transition: 0.3s;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical:active,
::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(95, 91, 91, 1);
}
