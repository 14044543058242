
.heatmapCircle{
    stroke-width: 0.25rem;
    stroke-opacity: 20%;
    fill-opacity: 0;
    transition: all 0.3s;
}

.heatmapCircle:hover{
    stroke-width: 0.5rem;
    stroke-opacity: 40%;
    fill-opacity: 20%;
    transition: all 0.3s;
}